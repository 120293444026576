import React, { useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { Close, CustomEnvelop } from "../icons";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const NewsletterSubscription = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await addToMailchimp(email, {
      FULLNAME: fullName,
    });
    setLoading(false);
    setResponse(result);
    setFullName("");
    setEmail("");
  };

  const closeNotification = () => {
    setResponse(null);
  };
  const { t } = useTranslation();
  return (
    <section className="email-subs">
      <div className="container">
        <div className="email-subs-wrap text-center">
          <div className="icon">
            <CustomEnvelop />
          </div>
          <h2 className="h1 email-subs-section-title">
            {t("Subscribe to newsletter")}
          </h2>
          <div className="email-subs-section-description">
            {t("newsletter_text")}
          </div>
          <div className="form-wrap">
            <form
              onSubmit={(e) => handleSubmit(e)}
              className={`subscribe-form text-left${loading ? " loading" : ""}`}
              data-members-form="subscribe"
            >
              <div className="form-field-wrap field-group-inline">
                <label htmlFor="name" className="sr-only">
                  {t("Name")}
                </label>
                <input
                  type="text"
                  name="name"
                  className="name form-field input-field"
                  id="name"
                  placeholder="Your name"
                  required
                  autoComplete="off"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                <label htmlFor="email" className="sr-only">
                  {t("Email")}
                </label>
                <input
                  type="email"
                  name="email"
                  className="email form-field input-field"
                  id="email"
                  placeholder="Your email address1"
                  required
                  autoComplete="off"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className="btn form-field" type="submit">
                  <span>{t("Subscribe")}</span>
                </button>
              </div>
              <div className="message-container">
                {response && (
                  <div
                    className={`notification visible form-notification ${response.result}`}
                  >
                    <div
                      className="notification-close"
                      aria-label="close notification"
                      onClick={closeNotification}
                      onKeyDown={closeNotification}
                      role="button"
                      tabIndex={-1}
                    >
                      <span className="close-icon">
                        <Close />
                      </span>
                    </div>
                    <strong>{`${response.result.toUpperCase()}: `}</strong>
                    {t(response.msg)}
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsletterSubscription;
export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
